import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from './sidebar'; // Assuming you have a sidebar component
import RelatedPost from './related-post';

const BlogDetails = ({ blogData, publicUrl, postData }) => {
	// const arrayToObject = (array) => {
	// 	return array?.reduce((acc, curr, index) => {
	// 		acc[index] = curr;
	// 		return acc;
	// 	}, {});
	// };
		console.log(postData[0]);
		
	// Usage
	// const blogDataObject = arrayToObject(postData);
	// console.log(blogDataObject);

	if (!blogData) {
        return <div className="loader">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="#007bff"
            version="1.1"
            id="Capa_1"
            viewBox="0 0 32.707 32.707"
            width="100px"
            height="100px"
            style={{
                margin: "auto",
                background: "transparent",
                display: "block",
                shapeRendering: "auto",
            }}
        >
            <g>
                <path d="M21.477,4.511c0,2.487-2.021,4.508-4.508,4.508c-2.49,0-4.509-2.021-4.509-4.508c0-2.49,2.02-4.511,4.509-4.511 C19.458,0,21.477,2.021,21.477,4.511z M16.972,27.68c-1.386-0.002-2.513,1.119-2.517,2.508c-0.003,1.391,1.117,2.518,2.505,2.52 c1.39,0.004,2.517-1.117,2.519-2.506C19.484,28.811,18.361,27.684,16.972,27.68z M31.451,17.352c0-0.906-0.734-1.641-1.641-1.641 c-0.908,0-1.644,0.732-1.644,1.641c0,0.904,0.733,1.643,1.644,1.643C30.716,18.994,31.451,18.258,31.451,17.352z M6.995,17.352 c0-1.585-1.284-2.87-2.87-2.87s-2.869,1.285-2.869,2.87c0,1.584,1.283,2.869,2.869,2.869S6.995,18.936,6.995,17.352z M26.962,7.354 c-0.504-0.506-1.319-0.504-1.825,0c-0.505,0.506-0.505,1.328,0,1.832c0.506,0.506,1.321,0.506,1.825,0S27.466,7.86,26.962,7.354z M9.973,24.354c-1.152-1.146-3.019-1.146-4.17-0.002c-1.151,1.146-1.152,3.012,0,4.16c1.152,1.148,3.018,1.15,4.168,0 C11.125,27.367,11.125,25.502,9.973,24.354z M27.251,27.631c0.658-0.662,0.658-1.734-0.002-2.396 c-0.658-0.658-1.726-0.658-2.385,0.004c-0.658,0.66-0.658,1.732,0,2.395C25.525,28.293,26.591,28.293,27.251,27.631z M10.047,10.427c1.188-1.189,1.188-3.119,0-4.311c-1.188-1.189-3.115-1.189-4.305,0c-1.188,1.189-1.188,3.119,0.001,4.311 S8.858,11.618,10.047,10.427z" />
            </g>
            <style>
                {`
                @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
                }
                .loader {
                animation: spin 1s linear infinite;
                }
            `}
            </style>
        </svg>
    </div>;
    }
  let imageAlt = 'Blog Image';
  let imageFetchPath = 'https://sportysphere.com/public/img/';
  return (
    <div className="blog-page-area pd-bottom-80 go-top">
		  <div className="container">
		    <div className="row">
		      <div className="col-lg-9 pd-top-50">
		        <div className="blog-details-page-inner">
		          <div className="single-blog-inner m-0">
		            <div className="single-post-wrap style-overlay">
		              <div className="thumb">
		                <img src={blogData.post_image ? `${blogData.post_image}` : `${postData[0].post_image}`} alt={imageAlt} />
		              </div>
		              <div className="details pb-4">
		                <div className="post-meta-single mb-2">
		                  <ul>
		                    <li><a className="tag-base tag-blue" href="#">{blogData.category ? blogData.category : postData[0].category}</a></li>
		                    <li><p><i className="fa fa-clock-o" />{blogData.created_at ? new Date(blogData.created_at).toLocaleDateString()  : new Date(postData[0].created_at).toLocaleDateString() }</p></li>
		                    <li><i className="fa fa-user" />{blogData.author ? blogData.author : postData[0].author}</li>
		                  </ul>
		                </div>
		                <h5 className="title mt-0">{blogData.title ? blogData.title : postData[0].title}</h5>
		              </div>
		            </div>
		            {/* Render HTML content */}
					<div dangerouslySetInnerHTML={{ __html: blogData.content ? blogData.content.replace(/\\n|\\|\?/g, '') :  postData[0].content.replace(/\\n|\\|\?/g, '')}} />
		            <div className="meta">
		              <div className="row">
		                <div className="col-lg-5">
		                  <div className="tags">
		                    <span>Tags: </span>
		                    {blogData.meta_tags ? blogData.meta_tags.replace(/\\n|\\|\\\?|["<>]/g, '') : postData[0].meta_tags.replace(/\\n|\\|\\\?|["<>]/g, '') }
		                  </div>
		                </div>
		                <div className="col-lg-7 text-md-right">
		                  <div className="blog-share">
		                    <span>Share:</span>
		                    <ul className="social-area social-area-2 d-inline">
		                      <li><a className="facebook-icon" href="#"><i className="fa fa-facebook" /></a></li>
		                      <li><a className="twitter-icon" href="#"><i className="fa fa-twitter" /></a></li>
		                      <li><a className="youtube-icon" href="#"><i className="fa fa-youtube-play" /></a></li>
		                      <li><a className="instagram-icon" href="#"><i className="fa fa-instagram" /></a></li>
		                      <li><a className="google-icon" href="#"><i className="fa fa-google-plus" /></a></li>
		                    </ul>
		                  </div>
		                </div>
		              </div>
		            </div>
		          </div>
		          <RelatedPost publicUrl={publicUrl} categoryId={blogData.category_id} imageFetchPath={imageFetchPath} blogId={blogData.id} />
		          
		        </div>
		      </div>
			  <Sidebar />
		    </div>
		  </div>
		</div>
  );
};

export default BlogDetails;



