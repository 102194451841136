import { createSlice } from '@reduxjs/toolkit'

const initialState = []

export const blogSlice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    BlogsData: (state, action) => {
         state.push(action.payload); // Update the state with the payload data (blogs list)
      },
  },
})

// Export the action and reducer
export const { BlogsData } = blogSlice.actions
export default blogSlice.reducer  // Export the reducer to be used in the store