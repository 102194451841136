import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

class NewPapper extends Component {
  state = {
    posts: [],
  };

  componentDidMount() {
    // Fetch the data from the API
    const apiUrl = 'https://sportysphere.com/api/blog.php?news=All&limit=4';
    axios.get(apiUrl)
      .then((response) => {
        // Update the state with the fetched data
        this.setState({ posts: response.data });
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }

  render() {
    const { posts } = this.state;
    let publicUrl = process.env.PUBLIC_URL + '/';
    let imageFetchPath = 'https://sportysphere.com/public/img/';
    const formatDate = (dateString) => {
      if (!dateString) return 'N/A';
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
      });
    };

    return (
      <div className="newsletter-area bg-black pd-top-80 pd-bottom-50">
        <div className="container">
          <div className="row">
            <div className="col-xl-9 col-lg-8">
              <div className="row">
                {posts.map((post, index) => (
                  <div className="col-xl-6 col-lg-12" key={index}>
                    <div className="single-post-list-wrap style-two style-white text-left">
                      <div className="media">
                        <div className="media-left">
                          <img src={post.post_image ? `${post.post_image}` : `${publicUrl}assets/img/video/2.png`} alt="img" width={300} />
                        </div>
                        <div className="media-body align-self-center">
                          <div className="details">
                            <div className="post-meta-single">
                              <ul>
                                <li><Link to={`/cat-news/${post.category_id}`} className="tag-base tag-blue">{post.category || 'N/A'}</Link></li>
                                <li><i className="fa fa-clock-o" />{formatDate(post.updated_at || post.created_at)}</li>
                              </ul>
                            </div>
                            <Link to={{ pathname: `/news-details/${post.id}`}}>  <h6 className="title mt-2">{post.title}</h6></Link>
                          
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-xl-3 col-lg-4">
              <form className="single-newsletter-inner bg-blue text-center">
                <h5>Newsletter</h5>
                <p>Stay Updated on all that's new and noteworthy</p>
                <div className="single-input-inner">
                  <input type="text" placeholder="Enter Your Name" />
                </div>
                <div className="single-input-inner">
                  <input type="text" placeholder="Enter Your Email" />
                </div>
                <a className="btn btn-white w-100" href="#">Subscribe Now</a>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewPapper;
