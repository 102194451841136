import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class NavbarV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
	  Newscategories: [],
    };
  }

  componentDidMount() {
    // Fetch categories when component mounts
    this.fetchCategories();
  }

  fetchCategories() {
    const apiUrlBlogCat = 'https://sportysphere.com/api/blog.php?category=blog_categories';
	const apiUrlNewsCat = 'https://sportysphere.com/api/blog.php?category=news_categories';

    fetch(apiUrlBlogCat)
      .then(response => response.json())
      .then(data => {
        // Assuming the data is an array of categories
        if (Array.isArray(data)) {
          this.setState({ categories: data });
        } else {
          console.error('Unexpected data format:', data);
        }
      })
      .catch(error => console.error('Error fetching categories:', error));

	  fetch(apiUrlNewsCat)
      .then(response => response.json())
      .then(data => {
        // Assuming the data is an array of categories
        if (Array.isArray(data)) {
          this.setState({ Newscategories: data });
        } else {
          console.error('Unexpected data format:', data);
        }
      })
      .catch(error => console.error('Error fetching categories:', error));
  }

  render() {
    let publicUrl = process.env.PUBLIC_URL + '/';

    // Extract categories from state
    const { categories } = this.state;
	const { Newscategories } = this.state;

    return (
      <div className="navbar-area">
        {/* topbar start */}
        <div className="topbar-area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-9 align-self-center">
                <div className="topbar-menu text-md-left text-center">
                  {/* Part 1: Dynamic category links */}
                  <ul className="align-self-center">
                    <li><Link><i className="fa fa-envelope-o" /> Info@sportysphere.com</Link></li>
                    <li><Link><i className="fa fa-envelope-o" /> content@sportysphere.com</Link></li>
                    <li><Link><i className="fa fa-envelope-o" /> newsletters@sportysphere.com</Link></li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-3 text-md-right text-center mt-2 mt-md-0">
                <div className="topbar-social">
                  <div className="topbar-date d-none d-lg-inline-block">
                    {/* <i className="fa fa-calendar" /> Saturday, October 7 */}
                  </div>
                  <ul className="social-area social-area-2">
                    <li><a className="facebook-icon" href="https://www.facebook.com/profile.php?id=61566769855926"><i className="fa fa-facebook" /></a></li>
                    <li><a className="instagram-icon" href="#"><i className="fa fa-instagram" /></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* topbar end */}
        {/* adbar end */}
        <div className="adbar-area d-none d-lg-block">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-5 align-self-center">
                <div className="logo text-md-left text-center">
                  <Link className="main-logo" to="/"><img src={publicUrl + "assets/img/logo-2.png"} alt="img" /></Link>
                </div>
              </div>
              {/* <div className="menu-search-inner " style={{marginLeft: "auto"}}>
                <input type="text" placeholder="Search For" />
                <button type="submit" className="submit-btn"><i className="fa fa-search" /></button>
              </div> */}
              {/* <div className="col-lg-4 align-self-center">
              <div className="nav-right-part nav-right-part-desktop">
          
            </div>
            </div> */}
              {/* <div className="col-xl-6 col-lg-7 text-md-right text-center">
                <a href="#" className="adbar-right">
                  <img alt="img" />
                </a>
              </div> */}
            </div>
          </div>
        </div>
        {/* adbar end */}
        {/* navbar start */}
        <nav className="navbar navbar-expand-lg">
          <div className="container nav-container">
            <div className="responsive-mobile-menu">
              <div className="logo d-lg-none d-block">
                <Link className="main-logo" to="/"><img src="/assets/img/logo.png" alt="img" /></Link>
              </div>
              <button className="menu toggle-btn d-block d-lg-none" data-target="#sporty_main_menu" aria-expanded="false" aria-label="Toggle navigation">
                <span className="icon-left" />
                <span className="icon-right" />
              </button>
            </div>
            {/* <div className="nav-right-part nav-right-part-mobile">
              <a className="search header-search" href="#"><i className="fa fa-search" /></a>
            </div> */}
            <div className="collapse navbar-collapse" id="sporty_main_menu">
              <ul className="navbar-nav menu-open">
                <li className="current-menu-item">
                  <Link to="/">Home</Link>
                </li>
                {Newscategories.map(category => (
                      <li key={category.id}>
                        <Link to={`/cat-news/${category.id}`}>{category.category}</Link>
                      </li>
                    ))}
              </ul>
            </div>
         
          </div>
        </nav>
      </div>
    );
  }
}

export default NavbarV2;
