import React from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import NewsList from './news-components/news-list';
import Footer from './global-components/footer';

const newsPage = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="News"  />
        <NewsList />
        <Footer />
    </div>
}

export default newsPage

