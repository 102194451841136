import React, { useEffect } from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';

const PrivacyPolicy = () => {
    useEffect(() => {
        // Scroll to the top of the page
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <Navbar />
            <PageHeader headertitle="Privacy Policy" />
            <div className="container privacy-policy-content">
                <h2>Introduction</h2>
                <p>
                    Welcome to our Privacy Policy page. Your privacy is important to us. This policy outlines the types of information we collect from visitors to our website and how we use and protect that information.
                </p>

                <h2>Information We Collect</h2>
                <p>
                    As a news website presenting football articles and news, we do not require you to create an account or provide personal information to access our content. However, we may collect the following types of information:
                </p>
                <ul>
                    <li><strong>Browsing Data:</strong> We may collect information about your visits to our site, including your IP address, browser type, pages visited, and the time and date of your visits. This information helps us understand how our site is used and improve our content.</li>
                    <li><strong>Cookies:</strong> We use cookies to enhance your browsing experience, track site usage, and manage advertisements. You can control cookie preferences through your browser settings.</li>
                </ul>

                <h2>How We Use Your Information</h2>
                <p>
                    The information we collect may be used in the following ways:
                </p>
                <ul>
                    <li>To improve our website and enhance user experience.</li>
                    <li>To analyze site traffic and user behavior.</li>
                    <li>To deliver relevant advertisements (if applicable).</li>
                    <li>To communicate updates and news (if you choose to subscribe to our newsletter).</li>
                </ul>

                <h2>Data Protection and Security</h2>
                <p>
                    We implement various security measures to protect your browsing data. While we strive to use commercially acceptable means to protect your information, please be aware that no method of transmission over the Internet or electronic storage is 100% secure.
                </p>

                <h2>Your Rights</h2>
                <p>
                    As our site does not collect personal information for login or registration, your rights regarding your personal information are limited. However, you may:
                </p>
                <ul>
                    <li>Request details about the information we may have collected about you through cookies or browsing data.</li>
                    <li>Opt-out of cookies by adjusting your browser settings.</li>
                </ul>

                <h2>Cookies</h2>
                <p>
                    Our website uses cookies to enhance your experience and analyze site traffic. Cookies are small files stored on your device. You can choose to accept or decline cookies through your browser settings. Note that declining cookies may prevent you from taking full advantage of the website.
                </p>

                <h2>Third-Party Services</h2>
                <p>
                    We may use third-party services for analytics and advertising purposes. These services may collect information about your browsing habits on our site and other sites to deliver targeted advertisements.
                </p>

                <h2>Changes to This Privacy Policy</h2>
                <p>
                    We may update our Privacy Policy from time to time. Any changes will be reflected on this page, and we encourage you to review this policy periodically.
                </p>

                <h2>Contact Us</h2>
                <p>
                    If you have any questions about this Privacy Policy, please contact us:
                </p>
                <ul>
                    <li>Email: Info@sportysphere.com</li>
                    <li>Email: content@sportysphere.com</li>
                    <li>Email: newsletters@sportysphere.com</li>
                </ul>
            </div>
            <Footer />
        </div>
    );
};

export default PrivacyPolicy;
