import React, { useState } from 'react';
import Navbar from './global-components/navbar-v2';
import BannerV2 from './section-components/banner-v2';
import Trending from './section-components/trending-v3';
import Catv2 from './section-components/cat-section-v2';
import NewsLatter from './section-components/newsletter';
import Footer from './global-components/footer';
import Loader from './global-components/loader';

const Home_V2 = () => {
  const [loading, setLoading] = useState(true);

  return (
    <div>
      <Navbar />
      {/* {loading && <div style={{display: "flex", justifyContent: "center", marginTop: 10, gap: 20}}><Loader /> <Loader /> <Loader /></div>} Show loader when loading is true */}
      <BannerV2 setParentLoading={setLoading} />
      <Trending setParentLoading={setLoading}/>
      <Catv2 />
      <NewsLatter />
      <Footer />
    </div>
  );
};

export default Home_V2;
